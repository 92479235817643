<template>
    <section>
        <titulo-divisor titulo="Funcionario proveedor">
            <div class="row">
                <div class="icon-option my-auto">
                    <el-tooltip content="Crear funcionario" effect="light" visible-arrow>
                        <i class="icon-plus-circle f-30 cr-pointer"
                            @click="annadirFuncionario"
                        />
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div>

            <div class="row mx-0 custom-scroll overflow-auto mt-4 pt-4">
                <div v-for="(user, index) in funcionarios.data" :key="index" class="card-funcionario border mx-2 mb-5 text-muted br-4 cr-pointer position-relative">
                    <el-popover placement="bottom-end" trigger="hover" popper-class="popover-editar-eliminar">
                        <div class="row mx-0">
                            <div class="col-12 hover-options px-0">
                                <button class="btn f-12 w-100 text-left text-muted" type="button" name="button" @click="editarUser(user)">
                                    Editar
                                </button>
                            </div>
                            <div class="col-12 hover-options px-0">
                                <button class="btn f-12 w-100 text-left text-muted" type="button" name="button" @click="eliminarUser(user)">
                                    Eliminar
                                </button>
                            </div>
                        </div>
                        <i slot="reference" class="icon-dots-vertical position-absolute f-25" />
                    </el-popover>
                    <div class="cont-img position-relative text-center">
                        <img :src="user.foto | helper-storage" class="rounded-circle obj-cover my-auto mx-2" width="74" height="74" />
                    </div>
                    <div class="text-center nombre lh-19 f-12 pb-2 border-bottom2">
                        <p>{{user.nombre}}</p>
                        <p class="f-600">{{user.cargo}}</p>
                    </div>
                    <div class="text-center ops bg-white p-2">
                        <div class="row mx-0 px-2">
                            <i class="icon-cellphone-text f-18 mr-1 text-muted" />
                            <p class="my-auto f-12"> {{user.telefono}}</p>
                        </div>
                        <div class="row mx-0 px-2">
                            <i class="icon-mail-alt f-18 mr-1 text-muted" />
                            <p class="my-auto f-12"> {{user.correo}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-middle-center">
                <pagination :pagination="funcionarios" @paginate="listaFuncionarios" />
            </div>
        </div>
        <modal-crear-funcionario ref="openCrearModalFuncionario"/>
        <modal-editar-funcionario ref="openEditarModalFuncionario"/>
        <modal-eliminar
        ref="modalEliminar"
        title="Eliminar material"
        mensaje="¿Esta seguro que desea eliminar el fincionario?"
        @delete="eliminarUserModal"
        />
    </section>

</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import modalCrearFuncionario from '../partials/modalCrearFuncionario.vue'
import modalEditarFuncionario from '../partials/modalEditarFuncionario.vue'
export default {
    components: { modalCrearFuncionario, modalEditarFuncionario },
    data(){
        return{
            users:[
                {
                    id:1,
                    nombre:"Juan Monsalve",
                    foto:"funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png",
                    telefono:"312548511",
                    correo:"developergbp36@hotmail.com",
                    cargo:"Contador"
                },
                {
                    id:2,
                    nombre:"Pedro Miranda",
                    foto:"funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png",
                    telefono:"156165165",
                    correo:"pedito@hotmail.com",
                    cargo:"Contador"
                },
                {
                    id:3,
                    nombre:"Pablo Perez",
                    foto:"funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png",
                    telefono:"4235423532",
                    correo:"pablito@hotmail.com",
                    cargo:"Contador"
                },
                {
                    id:4,
                    nombre:"Luis Suarez",
                    foto:"funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png",
                    telefono:"2753275075",
                    correo:"luchi@hotmail.com",
                    cargo:"Contador"
                }
            ],
            idUser:null
        }

    },
    computed: {
        ...mapGetters({
            funcionarios: 'proveedores/proveedores_usuarios/funcionarios'
        }),
    },
    async mounted(){
        await this.listaFuncionarios()
    },
    methods:{
        ...mapActions({
            Action_lista_funcionarios: 'proveedores/proveedores_usuarios/Action_lista_funcionarios',
            Action_eliminar_funcionario: 'proveedores/proveedores_usuarios/Action_eliminar_funcionario'
            // uploadFile: 'files/files/Action_save_file_to_aws'
        }),
        editarUser(user){
            this.$refs.openEditarModalFuncionario.toggle(user)
        },
        async eliminarUserModal(){
            await this.Action_eliminar_funcionario({idProveedor: this.$route.params.id_proveedor, idFuncionario: this.idUser})
            await this.listaFuncionarios()
        },
        eliminarUser(user){
            this.idUser = user.id
            this.$refs.modalEliminar.toggle()
        },
        annadirFuncionario(){
            this.$refs.openCrearModalFuncionario.toggle()
        },
        async listaFuncionarios(page = 1){
            await this.Action_lista_funcionarios({idProveedor: this.$route.params.id_proveedor, page})
        }
    }


}

</script>
<style lang="scss" scoped>
.card-funcionario{
    max-width: 259px;
    min-width: 259px;
    background: #F1F2F4 !important;

    .cont-img{
        bottom: 20px;
        img{
            border: 3px solid #fff;
        }
    }
    .border-bottom2{
        border-bottom: 2px solid var(--color-general) !important;
    }
    .icon-dots-vertical{
        right: -2px;
        top: 4px;
        z-index: 10;
    }
}
</style>