var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditarFuncionario",attrs:{"tamano":"modal-lg","titulo":"Editar funcionario","adicional":"Guardar"},on:{"adicional":_vm.handleSaveProvider}},[_c('div',{staticClass:"ml-5"},[_c('required',{attrs:{"text":"Campos obligatorios"}})],1),_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row justify-content-center m-3 f-15"},[_c('div',{staticClass:"col-12 mb-4"},[_c('p',{staticClass:"f-11 text-muted text-center"},[_vm._v("Imagen funcionario")]),_c('clean-cropper',{ref:"cleanCropper",staticClass:"cropper-proveedor rounded-circle border cr-pointer mx-auto br-5",attrs:{"options":_vm.slimOptions,"image":_vm.model.imagen},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required|min:5|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre","size":"small"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"Cárgo","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("cargo "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"cargo","size":"small"},model:{value:(_vm.model.cargo),callback:function ($$v) {_vm.$set(_vm.model, "cargo", $$v)},expression:"model.cargo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"required|max:40|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Correo electronico "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Correo electronico","size":"small"},model:{value:(_vm.model.correo),callback:function ($$v) {_vm.$set(_vm.model, "correo", $$v)},expression:"model.correo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"telefono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Número de contacto "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Teléfono","size":"small"},model:{value:(_vm.model.telefono),callback:function ($$v) {_vm.$set(_vm.model, "telefono", $$v)},expression:"model.telefono"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"usuario","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Usuario"),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Usuario","size":"small"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Contraseña"),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"type":"password","placeholder":"Contraseña","size":"small"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }