<template>
    <modal
    ref="modalCrearFuncionario"
    tamano="modal-lg"
    titulo="Crear funcionario"
    adicional="Crear"
    @adicional="handleSaveProvider"
    >
        <div class="ml-5">
            <required text="Campos obligatorios"/>
        </div>
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-12 mb-4">
                        <p class="f-11 text-muted text-center">Imagen funcionario</p>
                        <clean-cropper
                        class="cropper-proveedor rounded-circle border cr-pointer mx-auto br-5"
                        ref="cleanCropper"
                        v-model="image"
                        :options="slimOptions"
                        :image="imagen"
                        />
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|min:5|max:60">
                        <p class="input-label-top">Nombre <required/></p>
                        <el-input v-model="model.nombre" placeholder="Nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="cargo" rules="required|max:30">
                        <p class="input-label-top">Cárgo <required/></p>
                        <el-input v-model="model.cargo" placeholder="cargo" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="correo" rules="required|max:40|email">
                        <p class="input-label-top">Correo electronico <required/></p>
                        <el-input v-model="model.correo" placeholder="Correo electronico" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="telefono" rules="required|numeric">
                        <p class="input-label-top">Número de contacto <required/></p>
                        <el-input v-model="model.telefono" placeholder="Teléfono" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <!-- <div class=" my-2">
                    <p class="col-12 text-muted f-13">Puede asignar un usuario y contraseña de acceso a este funcionario. En caso de no asignarla, el sistema lo hará por usted.</p>
                </div> -->
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="usuario" rules="required|max:20">
                        <p class="input-label-top">Usuario<required/></p>
                        <el-input v-model="model.username" placeholder="Usuario" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="password" rules="required|min:6|max:20">
                        <p class="input-label-top">Contraseña<required/></p>
                        <el-input type="password" v-model="model.password" placeholder="Contraseña" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Aws from '~/services/aws'

export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            imagen: null,
            image: null,
            model: {
                imagen: null,
                nombre: '',
                cargo: '',
                correo: '',
                telefono: '',
                username: '',
                password: '',
            }
        }
    },
    computed: {
        ...mapGetters({
            key: 'files/files/key'
        }),

    },
    methods: {
        ...mapActions({
            Action_crear_funcionarios: 'proveedores/proveedores_usuarios/Action_crear_funcionarios',
            Action_lista_funcionarios: 'proveedores/proveedores_usuarios/Action_lista_funcionarios',
            uploadFile: 'files/files/Action_save_file_to_aws'
        }),
        toggle(){
            this.clear()
            this.$refs.modalCrearFuncionario.toggle()
        },
        async handleSaveProvider(){
            const valid = await this.$refs.validator.validate()
            if(valid) {
                const base64Image = this.image
                if(base64Image) {
                    await this.uploadFile({
                        file: await this.base64ToFile(base64Image),
                        path: '/proveedores/funcionarios'
                    });
                }
                this.model.imagen = this.key
                await this.Action_crear_funcionarios({payload: this.model, idProveedor: this.$route.params.id_proveedor})
                this.$refs.modalCrearFuncionario.toggle()
                this.clear()
                await this.Action_lista_funcionarios({idProveedor:this.$route.params.id_proveedor})
            }
        },
        clear(){
            this.model.imagen = null
            this.model.nombre = ''
            this.model.cargo = ''
            this.model.correo = ''
            this.model.telefono = ''
            this.model.username = ''
            this.model.password = ''
        }
    }
}
</script>

<style lang="css" scoped>
.cropper-proveedor{
    width: 123px;
    height: 98px;
    background-color:#F5F5F5;
}
</style>
